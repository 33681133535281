import {User} from "../models/user";
import {BehaviorSubject} from "rxjs";
import {Answer} from "../models/answer";
import {HttpService} from "./http.service";

export class UserService {
    // @ts-ignore
    public loggedIn: BehaviorSubject <boolean> = new BehaviorSubject(false);

    public user?: User;

    private static _instance: UserService;

    public static getInstance(): UserService {
        if (!UserService._instance) {
            UserService._instance = new UserService();
        }

        return UserService._instance;
    }

    public async commitAnswer(answer: Answer) {
        const response = await fetch(`${HttpService.baseUrl}/commitAnswer`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                answer,
                userId: this.user?.id
            })
        });
        const objResponse = await response.json();
        if(objResponse.err) {
            console.log(`The answer was not committed.The error message is: ${objResponse.err.message}`)
        } else if(objResponse.OK) {
            console.log(`The answer successfully committed.`);
        }
    }
}