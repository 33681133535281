import {HttpService} from "./http.service";
import {ExpectedAnswer} from "../models/expected-answer";
import { Dictionary } from "lodash";
import {Answer} from "../models/answer";
import {StudentEvaluationInfo} from "../models/student-evaluation-info";
import {Client} from "../models/client";
import {UserService} from "./user.service";

export class EvaluationService {
    public expectedAnswers: ExpectedAnswer[] = [
    ];

    private static _instance: EvaluationService;

    public static getInstance(): EvaluationService {
        if (!EvaluationService._instance) {
            EvaluationService._instance = new EvaluationService();
        }

        return EvaluationService._instance;
    }

    public async getExpectedAnswers() {
        const response = await fetch(`${HttpService.baseUrl}/getExpectedAnswers`, {headers: {authorization: "ses.cipriandraghici.ro"}});
        const result = await response.json() as {expectedAnswers:  ExpectedAnswer[]};
        this.expectedAnswers = result.expectedAnswers;
        return this.expectedAnswers;
    }

    public async evaluateExam(answers: Dictionary<Dictionary<Answer>>) {
        const response = await fetch(`${HttpService.baseUrl}/getEvaluation`, {
                method: "post",
                headers: {
                    authorization: "ses.cipriandraghici.ro",
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    answers: answers
                })
            }
        );
        const result = await response.json() as Client[];
        return result;
    }
    // public buildEvaluationTableInfo(response: Dictionary<Dictionary<StudentEvaluationInfo>>) {
    //     const tableStructure: Client[] = [];
    //
    //     for(const clientId of Object.keys(response)) {
    //         const user = users.find(x => x.id === clientId);
    //         let client: Client = {...user} as Client;
    //         for(const questionId of Object.keys(response[clientId])) {
    //            client.evaluatedResponses[questionId] = response[clientId][questionId];
    //         }
    //         tableStructure.push()
    //     }
    // }
    //
    // public async evaluateExam(answers: Dictionary<Dictionary<Answer>>) {
    //     const response: Dictionary<Dictionary<StudentEvaluationInfo>> = {};
    //     const studentAnswers = answers;
    //     const expectedAnswers = this.expectedAnswers && this.expectedAnswers.length > 0 ? this.expectedAnswers : await this.getExpectedAnswers();
    //
    //     for(const clientId of Object.keys(studentAnswers)) {
    //         for(const questionId of Object.keys(studentAnswers[clientId])) {
    //             const answer = studentAnswers[clientId][questionId].optionsIds || [];
    //             const expectedAnswer = expectedAnswers.find(x => x.questionId === Number(questionId));
    //             if(!expectedAnswer) {
    //                 continue;
    //             }
    //             const expectedAnswerIds = expectedAnswer.expectedResponseIds || [];
    //             response[clientId] = {...response[clientId]};
    //             response[clientId][questionId] = {
    //                 evaluation: this.matchAnswer(answer, expectedAnswerIds),
    //                 answer,
    //                 expectedAnswer: expectedAnswerIds
    //             };
    //         }
    //     }
    //     console.log(response);
    //     return response;
    // }
    //
    // private matchAnswer(optionsIds: number[], expectedAnswersIds: number[]) {
    //     return expectedAnswersIds.every(x => optionsIds.find(y => y === x));
    // }
}