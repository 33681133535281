import React, { Component } from 'react'

interface ITimerProps {
    duration: number;
    onComplete: () => void;
}

export default class Timer extends Component<ITimerProps, any> {
    private myInterval: any;

    constructor(props: any) {
        super(props);

        this.state = {
            seconds: this.props.duration || 30,
        }
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }: any) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                clearInterval(this.myInterval);
                this.props.onComplete();
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { seconds } = this.state;
        return (
            <div>
                <h1>Timp ramas: {seconds < 10 ? `0${seconds}` : seconds} sec</h1>
                {
                    seconds <= (this.props.duration / 3)
                    ? <h5>Urmatoarea intrebare va fi disponibila in {seconds < 10 ? `0${seconds}` : seconds} sec</h5>
                    : <></>
                }
            </div>
        )
    }
}