import React from "react";
import {Label, Input} from "reactstrap";
import {observer} from "mobx-react";
import {AnswerOption} from "../../models/answer-option";

interface IAnswerAreaProps {
    option: AnswerOption;
    handleSelectionChanged: (option: AnswerOption) => void;
    checked?: boolean;
}

@observer
class RadioOption extends React.Component<IAnswerAreaProps, any>{
    render() {
        return (
            <Label check onChange={this.onSelectionChanged} style={{display: "flex"}}>
                <Input type="radio" checked={this.props.checked} onChange={() => {}} />
                {this.props.option.text}
            </Label>
        );
    }

    private onSelectionChanged = (e: any) => {
        if(e.target.checked) {
            this.props.option.checked = e.target.checked;
            this.props.handleSelectionChanged(this.props.option);
        }
    }
}

export default RadioOption;